$( document ).ready(function() {
    if ( !$('html').hasClass('hydrated') ) {
        _aos();
    }
});
const _aos = () => {
    setTimeout(() => {
        try {
            AOS.init({
                disable: function() {
                    let _matchMedia = '991.98px';
                    return window.matchMedia('(max-width: ' + _matchMedia + ')').matches;
                },
                duration: 600,
                easing: 'ease-out-back'
            });
        }
        catch (error) {
            console.log(error);
        }
    }, 320);
}